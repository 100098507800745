import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions, QueryFunctionContext } from 'react-query';
import { useMyFetcher } from '@/utils/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AnnouncementNotificationData = {
  __typename?: 'AnnouncementNotificationData';
  announcementId: Scalars['Int'];
};

export type AnnualReview = {
  __typename?: 'AnnualReview';
  creatorReview?: Maybe<CreatorReview>;
  readerReview?: Maybe<ReaderReview>;
};

export type Auth = {
  __typename?: 'Auth';
  /** access token for kadokado */
  accessToken: Scalars['String'];
};

export type Chapter = {
  __typename?: 'Chapter';
  collectionId: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  createdTime: Scalars['DateTime'];
  displayName: Scalars['String'];
  free: Scalars['Boolean'];
  id: Scalars['Int'];
  listingEndDate: Scalars['DateTime'];
  /** 若為date的最大值，則視為未發布，其他情況視為已排程 */
  listingStartDate: Scalars['DateTime'];
  /**
   * 發布狀態：1. 未發布，2. 已排程，3. 已發布
   *
   */
  listingStatus: ListingStatus;
  originalName?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  rental?: Maybe<Scalars['Int']>;
  sequenceNum: Scalars['Int'];
  titleId: Scalars['Int'];
  updatedTime: Scalars['DateTime'];
  /** 章節字數 */
  wordCount: Scalars['Int'];
};

export type ChapterInComment = {
  __typename?: 'ChapterInComment';
  chapterDisplayName: Scalars['String'];
  collectionDisplayName: Scalars['String'];
  titleDisplayName: Scalars['String'];
  titleId: Scalars['Float'];
};

export type ChapterInMyComment = {
  __typename?: 'ChapterInMyComment';
  displayName: Scalars['String'];
  id: Scalars['Float'];
  titleDisplayName: Scalars['String'];
  titleId: Scalars['Float'];
};

export type ChapterWithoutContent = {
  __typename?: 'ChapterWithoutContent';
  collectionId: Scalars['Int'];
  createdTime: Scalars['DateTime'];
  displayName: Scalars['String'];
  free: Scalars['Boolean'];
  id: Scalars['Int'];
  listingEndDate: Scalars['DateTime'];
  /** 若為date的最大值，則視為未發布，其他情況視為已排程 */
  listingStartDate: Scalars['DateTime'];
  /**
   * 發布狀態：1. 未發布，2. 已排程，3. 已發布
   *
   */
  listingStatus: ListingStatus;
  originalName?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  rental?: Maybe<Scalars['Int']>;
  sequenceNum: Scalars['Int'];
  titleId: Scalars['Int'];
  updatedTime: Scalars['DateTime'];
  /** 章節字數 */
  wordCount: Scalars['Int'];
};

export type CheckMyReportsInput = {
  commentIds: Array<Scalars['Int']>;
};

export type CheckMyReportsResult = {
  __typename?: 'CheckMyReportsResult';
  reportedCommentIds: Array<Scalars['Int']>;
};

export type CheckResult = {
  __typename?: 'CheckResult';
  exists: Scalars['Boolean'];
};

/** Allowed image prefixes */
export enum CloudImagePrefix {
  BankAccountInfo = 'bankAccountInfo',
  ChapterContent = 'chapterContent',
  Cover = 'cover',
  Profile = 'profile',
  VerifiedAuthor = 'verifiedAuthor'
}

export type Collection = {
  __typename?: 'Collection';
  chapters: Array<ChapterWithoutContent>;
  countOfChapters: Scalars['Int'];
  createdTime: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['Int'];
  originalName?: Maybe<Scalars['String']>;
  sequenceNum: Scalars['Int'];
  titleId: Scalars['Int'];
  updatedTime: Scalars['DateTime'];
};


export type CollectionChaptersArgs = {
  listingStatus?: InputMaybe<ListingStatus>;
};

export type Comment = {
  __typename?: 'Comment';
  chapterId?: Maybe<Scalars['Int']>;
  collectionId?: Maybe<Scalars['Int']>;
  commenterId: Scalars['Int'];
  content: Scalars['String'];
  createdTime: Scalars['DateTime'];
  deletedTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isPinned: Scalars['Boolean'];
  mentionedMemberId?: Maybe<Scalars['Int']>;
  paragraph?: Maybe<Scalars['String']>;
  parentCommentId?: Maybe<Scalars['Int']>;
  titleId?: Maybe<Scalars['Int']>;
  updatedTime: Scalars['DateTime'];
};

export type CommentListItem = {
  __typename?: 'CommentListItem';
  /** 作品作者是否已讀 */
  authorIsChecked?: Maybe<Scalars['Boolean']>;
  chapter?: Maybe<ChapterInComment>;
  chapterId?: Maybe<Scalars['Int']>;
  childrenCount: Scalars['Int'];
  collectionId?: Maybe<Scalars['Int']>;
  /** null if comment is deleted */
  commenterAvatar?: Maybe<Scalars['String']>;
  /** 留言頭像完整路徑 */
  commenterAvatarUrl?: Maybe<Scalars['String']>;
  /** null if comment is deleted */
  commenterDisplayName?: Maybe<Scalars['String']>;
  /** null if comment is deleted */
  commenterId?: Maybe<Scalars['Float']>;
  /** null if comment is deleted */
  commenterKind?: Maybe<MemberKind>;
  /** null if comment is deleted */
  content?: Maybe<Scalars['String']>;
  createdTime: Scalars['DateTime'];
  deletedTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isPinned: Scalars['Boolean'];
  /** 是否為該作品的作者 */
  isTitleOwner?: Maybe<Scalars['Boolean']>;
  mentionedMemberDisplayName?: Maybe<Scalars['String']>;
  mentionedMemberId?: Maybe<Scalars['Int']>;
  paragraph?: Maybe<Scalars['String']>;
  parentCommentId?: Maybe<Scalars['Int']>;
  titleId?: Maybe<Scalars['Int']>;
  updatedTime: Scalars['DateTime'];
};

export type CommentNotificationData = {
  __typename?: 'CommentNotificationData';
  /** 大頭貼相對路徑 */
  avatar: Scalars['String'];
  /** 大頭貼完整路徑 */
  avatarUrl: Scalars['String'];
  chapterId: Scalars['Int'];
  /** 留言帳號名稱 */
  memberDisplayName: Scalars['String'];
  /** commenter id */
  memberId: Scalars['Int'];
  memberKind: MemberKind;
  parentCommentId: Scalars['Int'];
};

export type Contest = {
  __typename?: 'Contest';
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  content: Scalars['String'];
  displayName: Scalars['String'];
  groups: Array<ContestGroup>;
  id: Scalars['Int'];
  runningFrom: Scalars['DateTime'];
  runningTo: Scalars['DateTime'];
};

export type ContestGroup = {
  __typename?: 'ContestGroup';
  displayName: Scalars['String'];
  id: Scalars['Int'];
};

export type ContestTitle = {
  __typename?: 'ContestTitle';
  createdAt: Scalars['DateTime'];
  groupId: Scalars['Int'];
  id: Scalars['Int'];
  ownerId: Scalars['Int'];
  titleId: Scalars['Int'];
};

export type ContestTitleListInput = {
  contestId: Scalars['Int'];
  /** 目前頁數，從 1 開始 */
  current: Scalars['Int'];
  /** 每頁顯示的數量，預設為 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** 排序方式：參賽時間、收藏數、本週觀看數，預設為參賽時間 (最新到最舊) */
  orderBy?: InputMaybe<ContestTitleListOrder>;
  /** 是否包含 18 禁作品，預設不包含 */
  rRatedFilter?: InputMaybe<RRatedFilter>;
};

export enum ContestTitleListOrder {
  EnrolledAt = 'ENROLLED_AT',
  FavoriteCount = 'FAVORITE_COUNT',
  WeeklyViewCount = 'WEEKLY_VIEW_COUNT'
}

export type ContestTitleSearchInput = {
  contestId: Scalars['Int'];
  /** Current page number, start from 1. */
  current: Scalars['Int'];
  /** 當期投票結束時間 */
  endDate: Scalars['DateTime'];
  groupId?: InputMaybe<Scalars['Int']>;
  /** Number of items per page. */
  limit: Scalars['Int'];
  orderBy: SearchOrderBy;
  /** 當期投票開始時間 */
  startDate: Scalars['DateTime'];
  term?: InputMaybe<Scalars['String']>;
};

export type ContestableTitle = {
  __typename?: 'ContestableTitle';
  attended: Scalars['Boolean'];
  bannedAt?: Maybe<Scalars['DateTime']>;
  cover?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  titleId: Scalars['Int'];
};

export type CreateBankAccountInfoInput = {
  address: Scalars['String'];
  bankAccountName: Scalars['String'];
  bankAccountNumbers: Scalars['String'];
  bankName: Scalars['String'];
  bankbook: Array<FilePhoto>;
  identificationNumbers: Scalars['String'];
  identificationPicture: Array<FilePhoto>;
  mobileNumber: Scalars['String'];
  nationality: Nationality;
  realName: Scalars['String'];
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type CreateChapterInput = {
  collectionId: Scalars['Int'];
  /** JSON格式的字串。需要先對Object做JSON.stringify之後再傳入 */
  content?: InputMaybe<Scalars['String']>;
  displayName: Scalars['String'];
  listingEndDate?: InputMaybe<Scalars['DateTime']>;
  listingStartDate?: InputMaybe<Scalars['DateTime']>;
  originalName?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['Int']>;
  rental?: InputMaybe<Scalars['Int']>;
  sequenceNum?: InputMaybe<Scalars['Int']>;
  titleId: Scalars['Int'];
};

export type CreateCollectionInput = {
  displayName: Scalars['String'];
  originalName?: InputMaybe<Scalars['String']>;
  sequenceNum: Scalars['Int'];
  titleId: Scalars['Int'];
};

export type CreateCommentInput = {
  /** <p>留言的目標</p><p>titleId, collectionId, chapterId 只能擇一設定。</p> */
  chapterId?: InputMaybe<Scalars['Int']>;
  /** <p>留言的目標</p><p>titleId, collectionId, chapterId 只能擇一設定。</p> */
  collectionId?: InputMaybe<Scalars['Int']>;
  /** Content of comment. */
  content: Scalars['String'];
  /** Id of the user mentioned */
  mentionedMemberId?: InputMaybe<Scalars['Int']>;
  /** <p>paragraph的hash。目前尚未實作</p> */
  paragraphId?: InputMaybe<Scalars['Int']>;
  /** <p>上層留言 id</p> */
  parentCommentId?: InputMaybe<Scalars['Int']>;
  /** <p>留言的目標</p><p>titleId, collectionId, chapterId 只能擇一設定。</p> */
  titleId?: InputMaybe<Scalars['Int']>;
};

export type CreateTitleInput = {
  /** 需和coverPath同長度 */
  coverArtists?: InputMaybe<Array<Scalars['String']>>;
  /** 需和coverArtists同長度 */
  coverPath?: InputMaybe<Array<Scalars['String']>>;
  displayName: Scalars['String'];
  genreIds?: InputMaybe<Array<Scalars['Int']>>;
  isTranslatable: Scalars['Boolean'];
  logline?: InputMaybe<Scalars['String']>;
  rRated: Scalars['Boolean'];
  serialized: Scalars['Boolean'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  visible: Scalars['Boolean'];
};

export type CreateVerifiedAuthorInput = {
  /** 最長為32字元 */
  displayName: Scalars['String'];
  justification: Scalars['String'];
  /** 最長為32字元 */
  mobileNumber: Scalars['String'];
  pictures: Array<PictureInput>;
  /** 最長為32字元 */
  realName: Scalars['String'];
};

export type CreateViolationReportInput = {
  commentId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  violatedGuidelineId: Scalars['Int'];
};

export type CreatorReview = {
  __typename?: 'CreatorReview';
  bestReactTitle?: Maybe<ReviewTitle>;
  bestReactTitleCommentCount?: Maybe<Scalars['Int']>;
  bestReactTitleLikeCount?: Maybe<Scalars['Int']>;
  mostEngagedFans: Array<ReviewMember>;
  mostPublishedMonth: Scalars['Int'];
  publishedChapterCount: Scalars['Int'];
  publishedPercentage: Scalars['Float'];
  publishedTitleCount: Scalars['Int'];
  readerCount: Scalars['Int'];
  readerPercentage: Scalars['Float'];
  subscriberCount: Scalars['Int'];
  subscriberPercentage: Scalars['Float'];
};

export type DailyReportResult = {
  __typename?: 'DailyReportResult';
  date: Scalars['String'];
  favoriteCount: Scalars['Int'];
  readCount: Scalars['Int'];
  titleName: Scalars['String'];
};

export type DailyRevenue = {
  __typename?: 'DailyRevenue';
  date: Scalars['String'];
  revenueFromAds: Scalars['Float'];
  revenueFromPurchases: Scalars['Float'];
  revenueFromRentals: Scalars['Float'];
  total: Scalars['Float'];
};

export type DeleteCommentRes = {
  __typename?: 'DeleteCommentRes';
  affectedRows: Scalars['Int'];
};

export type DeleteRes = {
  __typename?: 'DeleteRes';
  affectedRows: Scalars['Int'];
};

export type Edge = {
  __typename?: 'Edge';
  /** 用於pagination */
  cursor?: Maybe<Scalars['String']>;
  node: CommentListItem;
};

export type ExtendedChapter = {
  __typename?: 'ExtendedChapter';
  collectionId: Scalars['Int'];
  /** 留言數 */
  commentCount: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  createdTime: Scalars['DateTime'];
  displayName: Scalars['String'];
  free: Scalars['Boolean'];
  id: Scalars['Int'];
  listingEndDate: Scalars['DateTime'];
  /** 若為date的最大值，則視為未發布，其他情況視為已排程 */
  listingStartDate: Scalars['DateTime'];
  /**
   * 發布狀態：1. 未發布，2. 已排程，3. 已發布
   *
   */
  listingStatus: ListingStatus;
  originalName?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  rental?: Maybe<Scalars['Int']>;
  sequenceNum: Scalars['Int'];
  titleId: Scalars['Int'];
  updatedTime: Scalars['DateTime'];
  /** 章節字數 */
  wordCount: Scalars['Int'];
};

export type FilePhoto = {
  description: Scalars['String'];
  path: Scalars['String'];
};

export type Genre = {
  __typename?: 'Genre';
  defaultTags: Array<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['Int'];
};

export type GetChapterCommentsInput = {
  /** 指定在哪個node之後。請傳入一個cursor的值 */
  after?: InputMaybe<Scalars['String']>;
  /** 最大50，最小1 */
  limit: Scalars['Int'];
  titleId?: InputMaybe<Scalars['Int']>;
};

export type GetCommentsOptions = {
  /** 指定在哪個 node 之後。請傳入一個 cursor 的值 */
  after?: InputMaybe<Scalars['String']>;
  chapterId?: InputMaybe<Scalars['Int']>;
  collectionId?: InputMaybe<Scalars['Int']>;
  /** 最大50，最小1 */
  limit?: InputMaybe<Scalars['Int']>;
  /** 抓取子留言時使用 */
  parentCommentId?: InputMaybe<Scalars['Int']>;
  titleId?: InputMaybe<Scalars['Int']>;
};

export type GetCommentsRes = {
  __typename?: 'GetCommentsRes';
  edges: Array<Edge>;
  pageInfo: PageInfo;
  parentComment?: Maybe<CommentListItem>;
};

export type GetGuestNotificationOptions = {
  /** 區間開始時間。若不設定，預設為結束時間的30天前 */
  from?: InputMaybe<Scalars['DateTime']>;
  /** 區間結束時間。若不設定，預設為現在時間 */
  to?: InputMaybe<Scalars['DateTime']>;
};

export type GetGuestNotificationOptionsV2 = {
  /** 選填，使用之前查詢取得的 endCursor 當作錨點繼續查詢 */
  after?: InputMaybe<Scalars['String']>;
  /** 區間開始時間。若不設定，預設為結束時間的30天前 */
  from?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  /** 區間結束時間。若不設定，預設為現在時間 */
  to?: InputMaybe<Scalars['DateTime']>;
};

export type GetNotificationOptions = {
  filter: NotificationFilterOptions;
  /** 區間開始時間。若不設定，預設為結束時間的30天前 */
  from?: InputMaybe<Scalars['DateTime']>;
  /** 區間結束時間。若不設定，預設為現在時間 */
  to?: InputMaybe<Scalars['DateTime']>;
};

export type GetNotificationOptionsV2 = {
  /** 選填，使用之前查詢取得的 endCursor 當作錨點繼續查詢 */
  after?: InputMaybe<Scalars['String']>;
  filter: NotificationFilterOptions;
  /** 區間開始時間。若不設定，預設為結束時間的30天前 */
  from?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  /** 區間結束時間。若不設定，預設為現在時間 */
  to?: InputMaybe<Scalars['DateTime']>;
};

export type GetVotesRes = {
  __typename?: 'GetVotesRes';
  info: VoteInfo;
  votes: Array<Scalars['Int']>;
};

export type ListMyCommentsInput = {
  /** Current page number, start from 1. */
  current: Scalars['Int'];
  /** 最大50，最小1 */
  limit: Scalars['Int'];
};

export type ListedContest = {
  __typename?: 'ListedContest';
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  attended: Scalars['Boolean'];
  displayName: Scalars['String'];
  id: Scalars['Int'];
  runningFrom: Scalars['DateTime'];
  runningTo: Scalars['DateTime'];
};

export type ListedContestTitle = {
  __typename?: 'ListedContestTitle';
  countOfFavorites: Scalars['Int'];
  coverPath?: Maybe<Scalars['String']>;
  enrolledAt: Scalars['DateTime'];
  id: Scalars['Int'];
  logline?: Maybe<Scalars['String']>;
  ownerDisplayName: Scalars['String'];
  ownerId: Scalars['Int'];
  rRated?: Maybe<Scalars['Boolean']>;
  readingCount: Scalars['Int'];
  titleDisplayName: Scalars['String'];
};

export type ListedContestTitlePagination = {
  __typename?: 'ListedContestTitlePagination';
  current: Scalars['Int'];
  data: Array<ListedContestTitle>;
  limit: Scalars['Int'];
  more: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type ListedMyComment = {
  __typename?: 'ListedMyComment';
  chapter: ChapterInMyComment;
  chapterId?: Maybe<Scalars['Int']>;
  collectionId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  mentionedMember?: Maybe<Scalars['Int']>;
  paragraph?: Maybe<Scalars['String']>;
  parentComment?: Maybe<Scalars['Int']>;
  titleId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ListedNews = {
  __typename?: 'ListedNews';
  deliveredTime: Scalars['DateTime'];
  headline: Scalars['String'];
  id: Scalars['Int'];
  type: NewsType;
};

/** 發布狀態 */
export enum ListingStatus {
  /** 已發布 */
  Listed = 'listed',
  /** 已排程（listingStartDate !== MAX_DATE） */
  Scheduled = 'scheduled',
  /** 未發布且未排程（listingStartDate === MAX_DATE） */
  Unlisted = 'unlisted'
}

export type Member = {
  __typename?: 'Member';
  avatar?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Float'];
  memberKind: MemberKind;
  /** 沒申請過是null */
  monetizationStatus?: Maybe<MonetizationStatus>;
  notifications: Array<Notification>;
  /** 通知列表v2, 可以進行分頁 */
  notificationsV2: NotificationV2Res;
  status: UserStatus;
  /** 該member的作品 */
  titles: Array<Title>;
};


export type MemberNotificationsArgs = {
  options: GetNotificationOptions;
};


export type MemberNotificationsV2Args = {
  options: GetNotificationOptionsV2;
};


export type MemberTitlesArgs = {
  serialized?: InputMaybe<Scalars['Boolean']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** 創作者身份 */
export enum MemberKind {
  /** 簽約作家 */
  Contracted = 'CONTRACTED',
  /** 單純讀者 */
  Reader = 'READER',
  /** 一般創作者（一般會員） */
  Regular = 'REGULAR',
  /** 認證作家 */
  Verified = 'VERIFIED'
}

/** 請款資料認證狀態 */
export enum MonetizationStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** 瀏覽第二層留言 */
  authorCheckComment: Scalars['Boolean'];
  /**
   * 投票
   * 1.UnprocessableEntityException: ALREADY_VOTED 此作品今天已經投過
   * 2.UnprocessableEntityException: NOT_ENOUGH_VOTES 可用票數不足
   *
   */
  castVote: Vote;
  /** 查詢認證作家名稱是否適用 */
  checkDisplayName: CheckResult;
  /** 新增作家銀行帳戶資訊 */
  createBankAccountInfo: Scalars['Boolean'];
  /** 新增章節。目前限制一個collection只能建500個章節 */
  createChapter: Chapter;
  /** 新增collection。目前限制單一作品只能建立100個collections */
  createCollection: PureCollection;
  /** 新增留言。titleId, collectionId, chapterId擇一設定。 */
  createComment: Comment;
  /**
   * 作品申請參賽
   * 1.NotFoundException: 找不到title或group或contest
   * 2.ForbiddenException: INVALID_OWNER_ID 不是自己的title
   * 3.ForbiddenException: TITLE_CONTRACTED 簽約作品不可參賽
   * 4.UnprocessableEntityException: NOT_ATTENDABLE_DATE 非比賽期間
   * 5.UnprocessableEntityException: REPEATEDLY_ATTEND 重複參賽
   *
   */
  createContestTitle: ContestTitle;
  /** 申請提領收益 */
  createRevenueWithdrawal: WithdrawalRecord;
  /** 新增作品。目前限制單一會員只能建立50個作品 */
  createTitle: PureTitle;
  /** 申請認證作家 */
  createVerifiedAuthor: VerifiedAuthor;
  /** 檢舉 */
  createViolationReport: Scalars['Boolean'];
  /** 重新申請 */
  deleteBankAccountInfo: Scalars['Boolean'];
  deleteChapter: UpdateOrDeleteChapterRes;
  deleteCollection: UpdateOrDeleteCollectionRes;
  /** 刪除留言。 */
  deleteComment: DeleteCommentRes;
  deleteTitle: UpdateOrDeleteTitleRes;
  /** 重新申請認證作家 */
  deleteVerifiedAuthor: DeleteRes;
  login: Auth;
  markNotificationSeen: Notification;
  /**
   * 設定精選留言：
   * 1.UnprocessableEntityException: ALREADY_UPDATED 狀態沒變因此不處理
   * 2.ForbiddenException: INVALID_OWNER_ID 非留言處文章作者
   *
   */
  pinComment: PinCommentRes;
  /**
   * 活動簽到
   *
   *       1. Bad Request: ALREADY_SIGNED_IN_TODAY 今日已簽到
   *
   *       2. Bad Request: NOT_IN_PROMOTION_PERIOD 非活動期間
   */
  promotionSignIn: PromotionMemberSignIn;
  shareContest: ShareContestRes;
  updateChapter: UpdateOrDeleteChapterRes;
  updateCollection: UpdateOrDeleteCollectionRes;
  /** 編輯留言 */
  updateComment: Comment;
  /** 更新member資料，目前只有同意條款的功能 */
  updateMe: Member;
  /** 更新章節排序，目前限制一次只能更新 3000 個 */
  updateSequenceNums: UpdateSequenceNumsRes;
  updateTitle: UpdateOrDeleteTitleRes;
  /**
   * 目前僅支援幾種 prefix 上傳，並且需要有對應的權限：
   * * cover：會存在memberId/`${memberId}/cover/...，
   * * profile/`${memberId}/profile/...，
   *
   */
  uploadImage: UploadResultV2;
};


export type MutationAuthorCheckCommentArgs = {
  commentId: Scalars['Int'];
};


export type MutationCastVoteArgs = {
  contestTitleId: Scalars['Int'];
};


export type MutationCheckDisplayNameArgs = {
  displayName: Scalars['String'];
};


export type MutationCreateBankAccountInfoArgs = {
  createBankAccountInfoInput: CreateBankAccountInfoInput;
};


export type MutationCreateChapterArgs = {
  createChapterData: CreateChapterInput;
};


export type MutationCreateCollectionArgs = {
  createCollectionData: CreateCollectionInput;
};


export type MutationCreateCommentArgs = {
  createCommentData: CreateCommentInput;
};


export type MutationCreateContestTitleArgs = {
  groupId: Scalars['Int'];
  titleId: Scalars['Int'];
};


export type MutationCreateTitleArgs = {
  createTitleData: CreateTitleInput;
};


export type MutationCreateVerifiedAuthorArgs = {
  createVerifiedAuthorInput: CreateVerifiedAuthorInput;
};


export type MutationCreateViolationReportArgs = {
  createViolationReportInput: CreateViolationReportInput;
};


export type MutationDeleteChapterArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCollectionArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTitleArgs = {
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  code: Scalars['String'];
};


export type MutationMarkNotificationSeenArgs = {
  id: Scalars['String'];
};


export type MutationPinCommentArgs = {
  id: Scalars['Int'];
  pin: Scalars['Boolean'];
};


export type MutationPromotionSignInArgs = {
  promotionId: Scalars['Int'];
};


export type MutationShareContestArgs = {
  shareContestInput: ShareContestInput;
};


export type MutationUpdateChapterArgs = {
  updateChapterData: UpdateChapterInput;
};


export type MutationUpdateCollectionArgs = {
  updateCollectionData: UpdateCollectionInput;
};


export type MutationUpdateCommentArgs = {
  id: Scalars['Int'];
  updateCommentData: UpdateCommentInput;
};


export type MutationUpdateMeArgs = {
  updateMemberData: UpdateMeInput;
};


export type MutationUpdateSequenceNumsArgs = {
  updateSequenceNumsData: UpdateSequenceNumsInput;
};


export type MutationUpdateTitleArgs = {
  updateTitleData: UpdateTitleInput;
};


export type MutationUploadImageArgs = {
  image: Scalars['Upload'];
  prefix: CloudImagePrefix;
};

export type MyCommentsPagination = {
  __typename?: 'MyCommentsPagination';
  current: Scalars['Int'];
  data: Array<ListedMyComment>;
  limit: Scalars['Int'];
  more: Scalars['Boolean'];
  total: Scalars['Int'];
};

/** 國籍 */
export enum Nationality {
  /** 本國國民 */
  Nationals = 'NATIONALS',
  /** 外國籍 */
  NonNationals = 'NON_NATIONALS'
}

export type News = {
  __typename?: 'News';
  content: Scalars['String'];
  deliveredTime: Scalars['DateTime'];
  headline: Scalars['String'];
  id: Scalars['Int'];
  type: NewsType;
};

/** Allowed News Type */
export enum NewsType {
  Creator = 'CREATOR',
  Marketing = 'MARKETING',
  System = 'SYSTEM'
}

export type Notification = {
  __typename?: 'Notification';
  /** 通知內容 */
  body: Scalars['String'];
  data?: Maybe<NotificationData>;
  /** 通知標題 */
  headline: Scalars['String'];
  id: Scalars['String'];
  /** 通知發送時間 */
  notifyTime: Scalars['DateTime'];
  seenAt?: Maybe<Scalars['DateTime']>;
  /** 目標留言 id */
  tCommentId?: Maybe<Scalars['Float']>;
  /** 目標作品 id */
  tTitleId?: Maybe<Scalars['Float']>;
  type: NotificationType;
};

export type NotificationData = AnnouncementNotificationData | CommentNotificationData;

/** 選則留言篩選類別 */
export enum NotificationFilterOptions {
  Comment = 'COMMENT',
  Regular = 'REGULAR',
  Update = 'UPDATE'
}

/** 通知類型 */
export enum NotificationType {
  /** 留言通知 */
  Comment = 'COMMENT',
  /** 創作中心 */
  Creator = 'CREATOR',
  /** 行銷通知 */
  Marketing = 'MARKETING',
  /** 個人通知 */
  Personal = 'PERSONAL',
  /** 系統通知 */
  System = 'SYSTEM',
  /** 作品更新通知 */
  Update = 'UPDATE'
}

export type NotificationV2Res = {
  __typename?: 'NotificationV2Res';
  /** 若為 null 代表沒有更多資料，可用於填入 after 參數 */
  endCursor?: Maybe<Scalars['String']>;
  items: Array<Notification>;
};

export enum Operation {
  Purchase = 'PURCHASE',
  Rental = 'RENTAL'
}

export type OverviewResult = {
  __typename?: 'OverviewResult';
  chapterCount: Scalars['Int'];
  favoriteCount: Scalars['Int'];
  readCount: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export type Picture = {
  __typename?: 'Picture';
  description: Scalars['String'];
  path: Scalars['String'];
};

export type PictureInput = {
  description: Scalars['String'];
  path: Scalars['String'];
};

export type PinCommentRes = {
  __typename?: 'PinCommentRes';
  isPinned: Scalars['Boolean'];
};

export type PinnedCommentModel = {
  __typename?: 'PinnedCommentModel';
  commenterAvatar: Scalars['String'];
  commenterDisplayName: Scalars['String'];
  commenterId: Scalars['Int'];
  commenterKind: MemberKind;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  isTitleOwner: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type Promotion = {
  __typename?: 'Promotion';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['Int'];
  runningFrom: Scalars['DateTime'];
  runningTo: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PromotionMemberSignIn = {
  __typename?: 'PromotionMemberSignIn';
  memberId: Scalars['Int'];
  occurredAt: Scalars['DateTime'];
  promotionId: Scalars['Int'];
};

/** Title without inferred additional properties */
export type PureCollection = {
  __typename?: 'PureCollection';
  createdTime: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['Int'];
  originalName?: Maybe<Scalars['String']>;
  sequenceNum: Scalars['Int'];
  titleId: Scalars['Int'];
  updatedTime: Scalars['DateTime'];
};

/** Title without inferred additional properties */
export type PureTitle = {
  __typename?: 'PureTitle';
  /** 若和coverPath不同長度，會回傳同長度Array，內容為空字串 */
  coverArtists: Array<Scalars['String']>;
  coverPath: Array<Scalars['String']>;
  createdTime: Scalars['DateTime'];
  deletedTime?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  genreIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
  /** 是否為簽約作品。若為簽約作品，創作者不能自行更新 */
  isContracted: Scalars['Boolean'];
  /** 是否支援繁簡轉換 */
  isTranslatable: Scalars['Boolean'];
  listingFrom: Scalars['DateTime'];
  logline?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  ownerId: Scalars['Int'];
  rRated: Scalars['Boolean'];
  serialized: Scalars['Boolean'];
  tags: Array<Scalars['String']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  visible: Scalars['Boolean'];
  /** 所有章節的字數總和 */
  wordCount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  chapter: ExtendedChapter;
  /** 取得章節的瀏覽數，return type 是可用於建立 Map 的 [number, number][]，key 為 chapterId，value 為 viewCount */
  chapterViewCountEntries: Array<Array<Scalars['Int']>>;
  /** 確認是否檢舉過 */
  checkMyReports: CheckMyReportsResult;
  /** 取得留言。titleId, collectionId, chapterId須擇一填。 */
  comments: GetCommentsRes;
  contest: Contest;
  /** 比賽分組列表（依id排序） */
  contestGroupList: Array<ContestGroup>;
  contestList: Array<ListedContest>;
  /** 可參賽作品列表（依組別分類） */
  contestableTitleList: Array<ContestableTitle>;
  /** 取得所有作品分類 */
  genres: Array<Genre>;
  /**
   * 取得作品的精選留言。
   * 沒有精選留言回 null。
   *
   */
  getPinnedComment?: Maybe<PinnedCommentModel>;
  /** 取得活動資訊 */
  getPromotion: Promotion;
  /** 取得簽到狀態 */
  getSignInStatus: Array<SignedInStatus>;
  /** 前台獨立頁參賽作品列表 */
  listContestTitle: ListedContestTitlePagination;
  listedContest?: Maybe<Contest>;
  me: Member;
  /** 需有editor權限 */
  member: Member;
  /**
   * 個人年度回顧
   * 1.NotFoundException: 找不到使用者的資料
   *
   */
  myAnnualReview: AnnualReview;
  /** 取得自己文章下的最新留言。 */
  myChapterComments: GetCommentsRes;
  /** 取得自己的最新留言。 */
  myComments: MyCommentsPagination;
  /** 已投稿的分組列表（依id排序） */
  myContestGroupList: Array<ContestGroup>;
  myOverview: OverviewResult;
  myStatisticsReport: Array<DailyReportResult>;
  myVerificationStatus?: Maybe<VerificationStatus>;
  /** 今日已投的票 */
  myVotedList: Array<Vote>;
  /** 取得公告內容 */
  news: News;
  /** 取得最新的公告(以公告時間排序) */
  newsList: Array<ListedNews>;
  /** 不需登入 */
  publicNotifications: Array<Notification>;
  /** 不需登入 */
  publicNotificationsV2: NotificationV2Res;
  /** 取得點數交易明細。會抓從startTime到startTime + 24小時的資料 */
  revenueDetail: RevenueDetail;
  /** 收益報表 */
  revenueReport: RevenueReport;
  /** 收益提領資訊 */
  revenueWithdrawal: RevenueWithdrawal;
  /** 搜尋參賽作品 */
  searchContestTitle: SearchedContestTitlesPagination;
  /** 目前只允許讀取本人的作品 */
  title: Title;
  /** 今日可用的票 */
  usableVoteList: GetVotesRes;
  /** 違規類別列表 */
  violatedGuidelineList: Array<ViolatedGuideline>;
};


export type QueryChapterArgs = {
  id: Scalars['Int'];
};


export type QueryChapterViewCountEntriesArgs = {
  titleId: Scalars['Float'];
};


export type QueryCheckMyReportsArgs = {
  input: CheckMyReportsInput;
};


export type QueryCommentsArgs = {
  options: GetCommentsOptions;
};


export type QueryContestArgs = {
  id: Scalars['Int'];
};


export type QueryContestGroupListArgs = {
  contestId: Scalars['Int'];
};


export type QueryContestableTitleListArgs = {
  groupId: Scalars['Int'];
};


export type QueryGetPinnedCommentArgs = {
  titleId: Scalars['Int'];
};


export type QueryGetPromotionArgs = {
  promotionId: Scalars['Int'];
};


export type QueryGetSignInStatusArgs = {
  promotionId: Scalars['Int'];
};


export type QueryListContestTitleArgs = {
  listContestTitleInput: ContestTitleListInput;
};


export type QueryMemberArgs = {
  id: Scalars['Int'];
};


export type QueryMyChapterCommentsArgs = {
  input: GetChapterCommentsInput;
};


export type QueryMyCommentsArgs = {
  input: ListMyCommentsInput;
};


export type QueryMyContestGroupListArgs = {
  contestId: Scalars['Int'];
};


export type QueryMyStatisticsReportArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type QueryMyVotedListArgs = {
  contestId: Scalars['Int'];
};


export type QueryNewsArgs = {
  id: Scalars['Int'];
};


export type QueryNewsListArgs = {
  limit: Scalars['Int'];
};


export type QueryPublicNotificationsArgs = {
  options?: InputMaybe<GetGuestNotificationOptions>;
};


export type QueryPublicNotificationsV2Args = {
  options?: InputMaybe<GetGuestNotificationOptionsV2>;
};


export type QueryRevenueDetailArgs = {
  revenueDetailOptions: RevenueDetailOptions;
};


export type QueryRevenueReportArgs = {
  options: RevenueReportOptions;
};


export type QuerySearchContestTitleArgs = {
  searchContestTitleInput: ContestTitleSearchInput;
};


export type QueryTitleArgs = {
  id: Scalars['Int'];
};


export type QueryUsableVoteListArgs = {
  contestId: Scalars['Int'];
};

export enum RRatedFilter {
  All = 'All',
  AllAges = 'AllAges',
  R18 = 'R18'
}

export type ReaderReview = {
  __typename?: 'ReaderReview';
  mostEngagedTitle?: Maybe<ReviewTitle>;
  mostReadGenreDisplayNames: Array<Scalars['String']>;
  mostRepliedComment?: Maybe<Scalars['String']>;
  mostRepliedCommentCreatedAt?: Maybe<Scalars['DateTime']>;
  mostRepliedCommentReplyCount?: Maybe<Scalars['Int']>;
  reactCount: Scalars['Int'];
  reactPercentage: Scalars['Float'];
  readAuthorCount: Scalars['Int'];
  readAuthorPercentage: Scalars['Float'];
  readChapterCount: Scalars['Int'];
  readPercentage: Scalars['Float'];
  readTitleCount: Scalars['Int'];
  visitedCount: Scalars['Int'];
  visitedPercentage: Scalars['Float'];
};

export type RevenueDetail = {
  __typename?: 'RevenueDetail';
  revenueDetailItems: Array<RevenueDetailItem>;
  total: Scalars['Float'];
};

export type RevenueDetailItem = {
  __typename?: 'RevenueDetailItem';
  chapterDisplayName: Scalars['String'];
  occurrences: Scalars['Int'];
  operation: Operation;
  revenue: Scalars['Float'];
  titleDisplayName: Scalars['String'];
};

export type RevenueDetailOptions = {
  /** 包含時區的ISO 8601 string。例如："2022-03-01T00:00:00.000+08:00" */
  startTime: Scalars['String'];
};

export type RevenueReportOptions = {
  /** 包含時區的ISO 8601 string。例如："2022-03-31T00:00:00.000+08:00"。為inclusive */
  endDate: Scalars['String'];
  /** 包含時區的ISO 8601 string。例如："2022-03-01T00:00:00.000+08:00"。為inclusive */
  startDate: Scalars['String'];
};

export type RevenueWithdrawal = {
  __typename?: 'RevenueWithdrawal';
  /** 累積總收益 */
  totalRevenue: Scalars['Float'];
  /** 可提領金額 */
  withdrawable: Scalars['Float'];
  /** 可提領廣告收益 */
  withdrawableFromAdRevenue: Scalars['Float'];
  /** 可提領銷售餘額 */
  withdrawableFromTransactions: Scalars['Float'];
  /** 提領紀錄 */
  withdrawalRecords: Array<WithdrawalRecord>;
};

export type ReviewMember = {
  __typename?: 'ReviewMember';
  avatar: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['Int'];
};

export type ReviewTitle = {
  __typename?: 'ReviewTitle';
  cover: Array<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['Int'];
  logline?: Maybe<Scalars['String']>;
  ownerDisplayName: Scalars['String'];
};

export enum SearchOrderBy {
  CurrentRank = 'CURRENT_RANK',
  LatestUpdate = 'LATEST_UPDATE',
  TotalRank = 'TOTAL_RANK'
}

export type SearchedContestTitle = {
  __typename?: 'SearchedContestTitle';
  currentVotes: Scalars['Int'];
  groupDisplayName: Scalars['String'];
  groupId: Scalars['Int'];
  id: Scalars['Int'];
  ownerDisplayName: Scalars['String'];
  ownerId: Scalars['Int'];
  titleCover?: Maybe<Scalars['String']>;
  titleDisplayName: Scalars['String'];
  titleId: Scalars['Int'];
  totalVotes: Scalars['Int'];
};

export type SearchedContestTitlesPagination = {
  __typename?: 'SearchedContestTitlesPagination';
  current: Scalars['Int'];
  data: Array<SearchedContestTitle>;
  limit: Scalars['Int'];
  more: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type ShareContestInput = {
  contestId: Scalars['Int'];
};

export type ShareContestRes = {
  __typename?: 'ShareContestRes';
  contestId: Scalars['Int'];
  occurredAt: Scalars['DateTime'];
};

export type SignedInStatus = {
  __typename?: 'SignedInStatus';
  date: Scalars['String'];
  hasSignedIn: Scalars['Boolean'];
};

export type Title = {
  __typename?: 'Title';
  /** 該title的collections */
  collections: Array<Collection>;
  /** 所有章節的留言數總和 */
  commentCount: Scalars['Int'];
  /** 若和coverPath不同長度，會回傳同長度Array，內容為空字串 */
  coverArtists: Array<Scalars['String']>;
  coverPath: Array<Scalars['String']>;
  createdTime: Scalars['DateTime'];
  deletedTime?: Maybe<Scalars['DateTime']>;
  displayName: Scalars['String'];
  favoriteCount: Scalars['Int'];
  /** 分類 */
  genres: Array<Genre>;
  id: Scalars['Int'];
  /** 是否為簽約作品。若為簽約作品，創作者不能自行更新 */
  isContracted: Scalars['Boolean'];
  /** 是否支援繁簡轉換 */
  isTranslatable: Scalars['Boolean'];
  /** 所有章節的按讚數總和 */
  likeCount: Scalars['Int'];
  listingFrom: Scalars['DateTime'];
  logline?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  ownerId: Scalars['Int'];
  rRated: Scalars['Boolean'];
  readCount: Scalars['Int'];
  serialized: Scalars['Boolean'];
  tags: Array<Scalars['String']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  visible: Scalars['Boolean'];
  /** 所有章節的字數總和 */
  wordCount: Scalars['Int'];
};

export type TitleEdge = {
  __typename?: 'TitleEdge';
  cursor: Scalars['String'];
  node: Title;
};

export type UpdateAgreedTermsInput = {
  /** 這個選項代表創作者中心同意條款，不是kadokado會員條款 */
  creator?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateChapterInput = {
  collectionId?: InputMaybe<Scalars['Int']>;
  /** JSON格式的字串。需要先對Object做JSON.stringify之後再傳入 */
  content?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  listingEndDate?: InputMaybe<Scalars['DateTime']>;
  listingStartDate?: InputMaybe<Scalars['DateTime']>;
  originalName?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['Int']>;
  rental?: InputMaybe<Scalars['Int']>;
  sequenceNum?: InputMaybe<Scalars['Int']>;
  /** 若為true，會將listingStartDate設為max date，狀態會變回未發布 */
  unschedule?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCollectionInput = {
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  originalName?: InputMaybe<Scalars['String']>;
  sequenceNum?: InputMaybe<Scalars['Int']>;
};

export type UpdateCommentInput = {
  /** Content of comment. */
  content: Scalars['String'];
  /** Id of the user mentioned */
  mentionedMemberId?: InputMaybe<Scalars['Int']>;
};

export type UpdateMeInput = {
  /** 同意條款項目 */
  agreedTerms?: InputMaybe<UpdateAgreedTermsInput>;
};

export type UpdateOrDeleteChapterRes = {
  __typename?: 'UpdateOrDeleteChapterRes';
  affectedRows: Scalars['Int'];
};

export type UpdateOrDeleteCollectionRes = {
  __typename?: 'UpdateOrDeleteCollectionRes';
  affectedRows: Scalars['Int'];
};

export type UpdateOrDeleteTitleRes = {
  __typename?: 'UpdateOrDeleteTitleRes';
  affectedRows: Scalars['Int'];
};

export type UpdateSequenceNumsInput = {
  sequenceNums: Array<UpdateSequenceNumParam>;
};

export type UpdateSequenceNumsRes = {
  __typename?: 'UpdateSequenceNumsRes';
  /** 更新的章節數 */
  updatedChapters: Scalars['Int'];
};

export type UpdateTitleInput = {
  /** 需和coverPath同長度 */
  coverArtists?: InputMaybe<Array<Scalars['String']>>;
  /** 需和coverArtists同長度 */
  coverPath?: InputMaybe<Array<Scalars['String']>>;
  displayName?: InputMaybe<Scalars['String']>;
  genreIds?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  isTranslatable?: InputMaybe<Scalars['Boolean']>;
  listingFrom?: InputMaybe<Scalars['DateTime']>;
  logline?: InputMaybe<Scalars['String']>;
  rRated?: InputMaybe<Scalars['Boolean']>;
  serialized?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type UploadResultV2 = {
  __typename?: 'UploadResultV2';
  /** Url to uploaded tiles */
  path: Scalars['String'];
  url: Scalars['String'];
};

/** 發布狀態 */
export enum UserStatus {
  /** 已刪除 */
  Deleted = 'DELETED',
  /** 用戶變更email，尚未驗證 */
  Invalid = 'INVALID',
  /** 已註冊，尚未驗證email */
  Registered = 'REGISTERED',
  /** 已驗證email */
  Valid = 'VALID'
}

/** Allowed Verification Status */
export enum VerificationStatus {
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED'
}

export type VerifiedAuthor = {
  __typename?: 'VerifiedAuthor';
  createdTime: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['Int'];
  justification: Scalars['String'];
  memberId: Scalars['Int'];
  mobileNumber: Scalars['String'];
  pictures: Array<Picture>;
  realName: Scalars['String'];
  updatedTime: Scalars['DateTime'];
  verificationStatus: VerificationStatus;
};

export type ViolatedGuideline = {
  __typename?: 'ViolatedGuideline';
  displayName: Scalars['String'];
  id: Scalars['Int'];
};

export type Vote = {
  __typename?: 'Vote';
  contestTitleId: Scalars['Int'];
  id: Scalars['Int'];
  votedAt: Scalars['DateTime'];
};

export type VoteInfo = {
  __typename?: 'VoteInfo';
  hasVotedDays?: Maybe<Scalars['Int']>;
};

export type WithdrawalRecord = {
  __typename?: 'WithdrawalRecord';
  amount: Scalars['Int'];
  id: Scalars['Int'];
  submissionTime: Scalars['DateTime'];
  withdrawalStatus: WithdrawalStatus;
};

export enum WithdrawalStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Reviewing = 'REVIEWING',
  Success = 'SUCCESS'
}

export type RevenueReport = {
  __typename?: 'revenueReport';
  dailyRevenueArr: Array<DailyRevenue>;
};

export type UpdateSequenceNumParam = {
  collectionId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  sequenceNum: Scalars['Int'];
};

export type CheckDisplayNameMutationVariables = Exact<{
  displayName: Scalars['String'];
}>;


export type CheckDisplayNameMutation = { __typename?: 'Mutation', checkDisplayName: { __typename?: 'CheckResult', exists: boolean } };

export type CheckMyReportsQueryVariables = Exact<{
  input: CheckMyReportsInput;
}>;


export type CheckMyReportsQuery = { __typename?: 'Query', checkMyReports: { __typename?: 'CheckMyReportsResult', reportedCommentIds: Array<number> } };

export type CreateBankAccountInfoMutationVariables = Exact<{
  createBankAccountInfoInput: CreateBankAccountInfoInput;
}>;


export type CreateBankAccountInfoMutation = { __typename?: 'Mutation', createBankAccountInfo: boolean };

export type CreateChapterMutationVariables = Exact<{
  createChapterData: CreateChapterInput;
}>;


export type CreateChapterMutation = { __typename?: 'Mutation', createChapter: { __typename?: 'Chapter', id: number, titleId: number, collectionId: number, sequenceNum: number, displayName: string, originalName?: string | null, content?: string | null, free: boolean, points?: number | null, rental?: number | null, listingStartDate: any, listingEndDate: any, createdTime: any, updatedTime: any } };

export type CreateCollectionMutationVariables = Exact<{
  createCollectionData: CreateCollectionInput;
}>;


export type CreateCollectionMutation = { __typename?: 'Mutation', createCollection: { __typename?: 'PureCollection', id: number, titleId: number, displayName: string, originalName?: string | null, sequenceNum: number, createdTime: any, updatedTime: any } };

export type CreateCommentMutationVariables = Exact<{
  createCommentData: CreateCommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'Comment', id: number, commenterId: number, content: string, titleId?: number | null, collectionId?: number | null, chapterId?: number | null, paragraph?: string | null, parentCommentId?: number | null, mentionedMemberId?: number | null, createdTime: any, updatedTime: any, deletedTime?: any | null } };

export type CreateContestTitleMutationVariables = Exact<{
  groupId: Scalars['Int'];
  titleId: Scalars['Int'];
}>;


export type CreateContestTitleMutation = { __typename?: 'Mutation', createContestTitle: { __typename?: 'ContestTitle', id: number, createdAt: any } };

export type CreateVerifiedAuthorMutationVariables = Exact<{
  createVerifiedAuthorInput: CreateVerifiedAuthorInput;
}>;


export type CreateVerifiedAuthorMutation = { __typename?: 'Mutation', createVerifiedAuthor: { __typename?: 'VerifiedAuthor', id: number, displayName: string, verificationStatus: VerificationStatus, createdTime: any } };

export type CreateViolationReportMutationVariables = Exact<{
  createViolationReportInput: CreateViolationReportInput;
}>;


export type CreateViolationReportMutation = { __typename?: 'Mutation', createViolationReport: boolean };

export type DeleteBankAccountInfoMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteBankAccountInfoMutation = { __typename?: 'Mutation', deleteBankAccountInfo: boolean };

export type DeleteChapterMutationVariables = Exact<{
  chapterId: Scalars['Int'];
}>;


export type DeleteChapterMutation = { __typename?: 'Mutation', deleteChapter: { __typename?: 'UpdateOrDeleteChapterRes', affectedRows: number } };

export type DeleteCollectionMutationVariables = Exact<{
  collectionId: Scalars['Int'];
}>;


export type DeleteCollectionMutation = { __typename?: 'Mutation', deleteCollection: { __typename?: 'UpdateOrDeleteCollectionRes', affectedRows: number } };

export type DeleteCommentMutationVariables = Exact<{
  commentId: Scalars['Int'];
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: { __typename?: 'DeleteCommentRes', affectedRows: number } };

export type GetAnnouncementByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAnnouncementByIdQuery = { __typename?: 'Query', news: { __typename?: 'News', id: number, type: NewsType, headline: string, content: string, deliveredTime: any } };

export type GetAnnouncementListQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type GetAnnouncementListQuery = { __typename?: 'Query', newsList: Array<{ __typename?: 'ListedNews', id: number, type: NewsType, headline: string, deliveredTime: any }> };

export type GetChapterQueryVariables = Exact<{
  chapterId: Scalars['Int'];
}>;


export type GetChapterQuery = { __typename?: 'Query', chapter: { __typename?: 'ExtendedChapter', id: number, titleId: number, collectionId: number, sequenceNum: number, displayName: string, originalName?: string | null, content?: string | null, free: boolean, points?: number | null, rental?: number | null, listingStartDate: any, listingEndDate: any, listingStatus: ListingStatus, createdTime: any, updatedTime: any } };

export type GetChapterListQueryVariables = Exact<{
  titleId: Scalars['Int'];
  listingStatus?: InputMaybe<ListingStatus>;
}>;


export type GetChapterListQuery = { __typename?: 'Query', title: { __typename?: 'Title', id: number, collections: Array<{ __typename?: 'Collection', id: number, titleId: number, displayName: string, sequenceNum: number, countOfChapters: number, chapters: Array<{ __typename?: 'ChapterWithoutContent', id: number, titleId: number, collectionId: number, displayName: string, sequenceNum: number, free: boolean, wordCount: number, listingStartDate: any, listingEndDate: any, listingStatus: ListingStatus }> }> } };

export type GetChapterListingStatusQueryVariables = Exact<{
  chapterId: Scalars['Int'];
}>;


export type GetChapterListingStatusQuery = { __typename?: 'Query', chapter: { __typename?: 'ExtendedChapter', listingStatus: ListingStatus } };

export type GetChapterSettingQueryVariables = Exact<{
  chapterId: Scalars['Int'];
}>;


export type GetChapterSettingQuery = { __typename?: 'Query', chapter: { __typename?: 'ExtendedChapter', id: number, titleId: number, displayName: string, originalName?: string | null, free: boolean, points?: number | null, rental?: number | null, listingStatus: ListingStatus } };

export type GetChapterSortingListQueryVariables = Exact<{
  titleId: Scalars['Int'];
}>;


export type GetChapterSortingListQuery = { __typename?: 'Query', title: { __typename?: 'Title', id: number, collections: Array<{ __typename?: 'Collection', id: number, displayName: string, chapters: Array<{ __typename?: 'ChapterWithoutContent', id: number, displayName: string, sequenceNum: number, listingStatus: ListingStatus }> }> } };

export type GetChapterViewCountEntriesQueryVariables = Exact<{
  titleId: Scalars['Float'];
}>;


export type GetChapterViewCountEntriesQuery = { __typename?: 'Query', chapterViewCountEntries: Array<Array<number>> };

export type GetCommentsQueryVariables = Exact<{
  options: GetCommentsOptions;
}>;


export type GetCommentsQuery = { __typename?: 'Query', comments: { __typename?: 'GetCommentsRes', edges: Array<{ __typename?: 'Edge', cursor?: string | null, node: { __typename?: 'CommentListItem', id: number, content?: string | null, titleId?: number | null, collectionId?: number | null, chapterId?: number | null, paragraph?: string | null, parentCommentId?: number | null, mentionedMemberId?: number | null, mentionedMemberDisplayName?: string | null, commenterId?: number | null, commenterAvatar?: string | null, commenterKind?: MemberKind | null, commenterDisplayName?: string | null, childrenCount: number, isTitleOwner?: boolean | null, createdTime: any, updatedTime: any, deletedTime?: any | null, isPinned: boolean } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean }, parentComment?: { __typename?: 'CommentListItem', id: number, content?: string | null, titleId?: number | null, collectionId?: number | null, chapterId?: number | null, paragraph?: string | null, parentCommentId?: number | null, mentionedMemberId?: number | null, mentionedMemberDisplayName?: string | null, commenterId?: number | null, commenterAvatar?: string | null, commenterKind?: MemberKind | null, commenterDisplayName?: string | null, childrenCount: number, isTitleOwner?: boolean | null, createdTime: any, updatedTime: any, deletedTime?: any | null, isPinned: boolean } | null } };

export type GetContestQueryVariables = Exact<{
  contestId: Scalars['Int'];
}>;


export type GetContestQuery = { __typename?: 'Query', contest: { __typename?: 'Contest', id: number, displayName: string, content: string, runningFrom: any, runningTo: any } };

export type GetContestGroupListQueryVariables = Exact<{
  contestId: Scalars['Int'];
}>;


export type GetContestGroupListQuery = { __typename?: 'Query', contestGroupList: Array<{ __typename?: 'ContestGroup', id: number, displayName: string }>, myContestGroupList: Array<{ __typename?: 'ContestGroup', id: number, displayName: string }> };

export type GetContestableTitleListQueryVariables = Exact<{
  groupId: Scalars['Int'];
}>;


export type GetContestableTitleListQuery = { __typename?: 'Query', contestableTitleList: Array<{ __typename?: 'ContestableTitle', titleId: number, displayName: string, cover?: string | null, attended: boolean }> };

export type GetMyChapterCommentsQueryVariables = Exact<{
  input: GetChapterCommentsInput;
}>;


export type GetMyChapterCommentsQuery = { __typename?: 'Query', myChapterComments: { __typename?: 'GetCommentsRes', edges: Array<{ __typename?: 'Edge', cursor?: string | null, node: { __typename?: 'CommentListItem', id: number, content?: string | null, titleId?: number | null, collectionId?: number | null, chapterId?: number | null, paragraph?: string | null, parentCommentId?: number | null, mentionedMemberId?: number | null, mentionedMemberDisplayName?: string | null, commenterId?: number | null, commenterAvatar?: string | null, commenterKind?: MemberKind | null, commenterDisplayName?: string | null, childrenCount: number, isTitleOwner?: boolean | null, createdTime: any, updatedTime: any, deletedTime?: any | null, authorIsChecked?: boolean | null, isPinned: boolean, chapter?: { __typename?: 'ChapterInComment', titleId: number, chapterDisplayName: string, collectionDisplayName: string, titleDisplayName: string } | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean } } };

export type GetPinnedCommentQueryVariables = Exact<{
  titleId: Scalars['Int'];
}>;


export type GetPinnedCommentQuery = { __typename?: 'Query', getPinnedComment?: { __typename?: 'PinnedCommentModel', id: number, content?: string | null, isTitleOwner: boolean, commenterDisplayName: string, commenterAvatar: string, commenterKind: MemberKind, commenterId: number, createdAt: any, updatedAt: any } | null };

export type GetRevenueDetailQueryVariables = Exact<{
  revenueDetailOptions: RevenueDetailOptions;
}>;


export type GetRevenueDetailQuery = { __typename?: 'Query', revenueDetail: { __typename?: 'RevenueDetail', total: number, revenueDetailItems: Array<{ __typename?: 'RevenueDetailItem', titleDisplayName: string, chapterDisplayName: string, operation: Operation, occurrences: number, revenue: number }> } };

export type GetViolationGuidelineQueryVariables = Exact<{ [key: string]: never; }>;


export type GetViolationGuidelineQuery = { __typename?: 'Query', violatedGuidelineList: Array<{ __typename?: 'ViolatedGuideline', id: number, displayName: string }> };

export type UpdateChapterMutationVariables = Exact<{
  updateChapterData: UpdateChapterInput;
}>;


export type UpdateChapterMutation = { __typename?: 'Mutation', updateChapter: { __typename?: 'UpdateOrDeleteChapterRes', affectedRows: number } };

export type UpdateChapterOrderMutationVariables = Exact<{
  updateSequenceNumsData: UpdateSequenceNumsInput;
}>;


export type UpdateChapterOrderMutation = { __typename?: 'Mutation', updateSequenceNums: { __typename?: 'UpdateSequenceNumsRes', updatedChapters: number } };

export type UpdateCollectionMutationVariables = Exact<{
  updateCollectionData: UpdateCollectionInput;
}>;


export type UpdateCollectionMutation = { __typename?: 'Mutation', updateCollection: { __typename?: 'UpdateOrDeleteCollectionRes', affectedRows: number } };

export type UpdateCommentReadStatusMutationVariables = Exact<{
  commentId: Scalars['Int'];
}>;


export type UpdateCommentReadStatusMutation = { __typename?: 'Mutation', authorCheckComment: boolean };

export type UpdatePinCommentMutationVariables = Exact<{
  pin: Scalars['Boolean'];
  commentId: Scalars['Int'];
}>;


export type UpdatePinCommentMutation = { __typename?: 'Mutation', pinComment: { __typename?: 'PinCommentRes', isPinned: boolean } };

export type UploadImageMutationVariables = Exact<{
  prefix: CloudImagePrefix;
  image: Scalars['Upload'];
}>;


export type UploadImageMutation = { __typename?: 'Mutation', uploadImage: { __typename?: 'UploadResultV2', path: string, url: string } };


export const CheckDisplayNameDocument = `
    mutation checkDisplayName($displayName: String!) {
  checkDisplayName(displayName: $displayName) {
    exists
  }
}
    `;
export const useCheckDisplayNameMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CheckDisplayNameMutation, TError, CheckDisplayNameMutationVariables, TContext>) =>
    useMutation<CheckDisplayNameMutation, TError, CheckDisplayNameMutationVariables, TContext>(
      ['checkDisplayName'],
      useMyFetcher<CheckDisplayNameMutation, CheckDisplayNameMutationVariables>(CheckDisplayNameDocument),
      options
    );
export const CheckMyReportsDocument = `
    query checkMyReports($input: CheckMyReportsInput!) {
  checkMyReports(input: $input) {
    reportedCommentIds
  }
}
    `;
export const useCheckMyReportsQuery = <
      TData = CheckMyReportsQuery,
      TError = unknown
    >(
      variables: CheckMyReportsQueryVariables,
      options?: UseQueryOptions<CheckMyReportsQuery, TError, TData>
    ) =>
    useQuery<CheckMyReportsQuery, TError, TData>(
      ['checkMyReports', variables],
      useMyFetcher<CheckMyReportsQuery, CheckMyReportsQueryVariables>(CheckMyReportsDocument).bind(null, variables),
      options
    );
export const useInfiniteCheckMyReportsQuery = <
      TData = CheckMyReportsQuery,
      TError = unknown
    >(
      variables: CheckMyReportsQueryVariables,
      options?: UseInfiniteQueryOptions<CheckMyReportsQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<CheckMyReportsQuery, CheckMyReportsQueryVariables>(CheckMyReportsDocument)
    return useInfiniteQuery<CheckMyReportsQuery, TError, TData>(
      ['checkMyReports.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const CreateBankAccountInfoDocument = `
    mutation createBankAccountInfo($createBankAccountInfoInput: CreateBankAccountInfoInput!) {
  createBankAccountInfo(createBankAccountInfoInput: $createBankAccountInfoInput)
}
    `;
export const useCreateBankAccountInfoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBankAccountInfoMutation, TError, CreateBankAccountInfoMutationVariables, TContext>) =>
    useMutation<CreateBankAccountInfoMutation, TError, CreateBankAccountInfoMutationVariables, TContext>(
      ['createBankAccountInfo'],
      useMyFetcher<CreateBankAccountInfoMutation, CreateBankAccountInfoMutationVariables>(CreateBankAccountInfoDocument),
      options
    );
export const CreateChapterDocument = `
    mutation createChapter($createChapterData: CreateChapterInput!) {
  createChapter(createChapterData: $createChapterData) {
    id
    titleId
    collectionId
    sequenceNum
    displayName
    originalName
    content
    free
    points
    rental
    listingStartDate
    listingEndDate
    createdTime
    updatedTime
  }
}
    `;
export const useCreateChapterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateChapterMutation, TError, CreateChapterMutationVariables, TContext>) =>
    useMutation<CreateChapterMutation, TError, CreateChapterMutationVariables, TContext>(
      ['createChapter'],
      useMyFetcher<CreateChapterMutation, CreateChapterMutationVariables>(CreateChapterDocument),
      options
    );
export const CreateCollectionDocument = `
    mutation createCollection($createCollectionData: CreateCollectionInput!) {
  createCollection(createCollectionData: $createCollectionData) {
    id
    titleId
    displayName
    originalName
    sequenceNum
    createdTime
    updatedTime
  }
}
    `;
export const useCreateCollectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCollectionMutation, TError, CreateCollectionMutationVariables, TContext>) =>
    useMutation<CreateCollectionMutation, TError, CreateCollectionMutationVariables, TContext>(
      ['createCollection'],
      useMyFetcher<CreateCollectionMutation, CreateCollectionMutationVariables>(CreateCollectionDocument),
      options
    );
export const CreateCommentDocument = `
    mutation createComment($createCommentData: CreateCommentInput!) {
  createComment(createCommentData: $createCommentData) {
    id
    commenterId
    content
    titleId
    collectionId
    chapterId
    paragraph
    parentCommentId
    mentionedMemberId
    createdTime
    updatedTime
    deletedTime
  }
}
    `;
export const useCreateCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCommentMutation, TError, CreateCommentMutationVariables, TContext>) =>
    useMutation<CreateCommentMutation, TError, CreateCommentMutationVariables, TContext>(
      ['createComment'],
      useMyFetcher<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument),
      options
    );
export const CreateContestTitleDocument = `
    mutation createContestTitle($groupId: Int!, $titleId: Int!) {
  createContestTitle(groupId: $groupId, titleId: $titleId) {
    id
    createdAt
  }
}
    `;
export const useCreateContestTitleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateContestTitleMutation, TError, CreateContestTitleMutationVariables, TContext>) =>
    useMutation<CreateContestTitleMutation, TError, CreateContestTitleMutationVariables, TContext>(
      ['createContestTitle'],
      useMyFetcher<CreateContestTitleMutation, CreateContestTitleMutationVariables>(CreateContestTitleDocument),
      options
    );
export const CreateVerifiedAuthorDocument = `
    mutation createVerifiedAuthor($createVerifiedAuthorInput: CreateVerifiedAuthorInput!) {
  createVerifiedAuthor(createVerifiedAuthorInput: $createVerifiedAuthorInput) {
    id
    displayName
    verificationStatus
    createdTime
  }
}
    `;
export const useCreateVerifiedAuthorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateVerifiedAuthorMutation, TError, CreateVerifiedAuthorMutationVariables, TContext>) =>
    useMutation<CreateVerifiedAuthorMutation, TError, CreateVerifiedAuthorMutationVariables, TContext>(
      ['createVerifiedAuthor'],
      useMyFetcher<CreateVerifiedAuthorMutation, CreateVerifiedAuthorMutationVariables>(CreateVerifiedAuthorDocument),
      options
    );
export const CreateViolationReportDocument = `
    mutation createViolationReport($createViolationReportInput: CreateViolationReportInput!) {
  createViolationReport(createViolationReportInput: $createViolationReportInput)
}
    `;
export const useCreateViolationReportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateViolationReportMutation, TError, CreateViolationReportMutationVariables, TContext>) =>
    useMutation<CreateViolationReportMutation, TError, CreateViolationReportMutationVariables, TContext>(
      ['createViolationReport'],
      useMyFetcher<CreateViolationReportMutation, CreateViolationReportMutationVariables>(CreateViolationReportDocument),
      options
    );
export const DeleteBankAccountInfoDocument = `
    mutation deleteBankAccountInfo {
  deleteBankAccountInfo
}
    `;
export const useDeleteBankAccountInfoMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBankAccountInfoMutation, TError, DeleteBankAccountInfoMutationVariables, TContext>) =>
    useMutation<DeleteBankAccountInfoMutation, TError, DeleteBankAccountInfoMutationVariables, TContext>(
      ['deleteBankAccountInfo'],
      useMyFetcher<DeleteBankAccountInfoMutation, DeleteBankAccountInfoMutationVariables>(DeleteBankAccountInfoDocument),
      options
    );
export const DeleteChapterDocument = `
    mutation deleteChapter($chapterId: Int!) {
  deleteChapter(id: $chapterId) {
    affectedRows
  }
}
    `;
export const useDeleteChapterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteChapterMutation, TError, DeleteChapterMutationVariables, TContext>) =>
    useMutation<DeleteChapterMutation, TError, DeleteChapterMutationVariables, TContext>(
      ['deleteChapter'],
      useMyFetcher<DeleteChapterMutation, DeleteChapterMutationVariables>(DeleteChapterDocument),
      options
    );
export const DeleteCollectionDocument = `
    mutation deleteCollection($collectionId: Int!) {
  deleteCollection(id: $collectionId) {
    affectedRows
  }
}
    `;
export const useDeleteCollectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCollectionMutation, TError, DeleteCollectionMutationVariables, TContext>) =>
    useMutation<DeleteCollectionMutation, TError, DeleteCollectionMutationVariables, TContext>(
      ['deleteCollection'],
      useMyFetcher<DeleteCollectionMutation, DeleteCollectionMutationVariables>(DeleteCollectionDocument),
      options
    );
export const DeleteCommentDocument = `
    mutation deleteComment($commentId: Int!) {
  deleteComment(id: $commentId) {
    affectedRows
  }
}
    `;
export const useDeleteCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCommentMutation, TError, DeleteCommentMutationVariables, TContext>) =>
    useMutation<DeleteCommentMutation, TError, DeleteCommentMutationVariables, TContext>(
      ['deleteComment'],
      useMyFetcher<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument),
      options
    );
export const GetAnnouncementByIdDocument = `
    query getAnnouncementById($id: Int!) {
  news(id: $id) {
    id
    type
    headline
    content
    deliveredTime
  }
}
    `;
export const useGetAnnouncementByIdQuery = <
      TData = GetAnnouncementByIdQuery,
      TError = unknown
    >(
      variables: GetAnnouncementByIdQueryVariables,
      options?: UseQueryOptions<GetAnnouncementByIdQuery, TError, TData>
    ) =>
    useQuery<GetAnnouncementByIdQuery, TError, TData>(
      ['getAnnouncementById', variables],
      useMyFetcher<GetAnnouncementByIdQuery, GetAnnouncementByIdQueryVariables>(GetAnnouncementByIdDocument).bind(null, variables),
      options
    );
export const useInfiniteGetAnnouncementByIdQuery = <
      TData = GetAnnouncementByIdQuery,
      TError = unknown
    >(
      variables: GetAnnouncementByIdQueryVariables,
      options?: UseInfiniteQueryOptions<GetAnnouncementByIdQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetAnnouncementByIdQuery, GetAnnouncementByIdQueryVariables>(GetAnnouncementByIdDocument)
    return useInfiniteQuery<GetAnnouncementByIdQuery, TError, TData>(
      ['getAnnouncementById.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetAnnouncementListDocument = `
    query getAnnouncementList($limit: Int!) {
  newsList(limit: $limit) {
    id
    type
    headline
    deliveredTime
  }
}
    `;
export const useGetAnnouncementListQuery = <
      TData = GetAnnouncementListQuery,
      TError = unknown
    >(
      variables: GetAnnouncementListQueryVariables,
      options?: UseQueryOptions<GetAnnouncementListQuery, TError, TData>
    ) =>
    useQuery<GetAnnouncementListQuery, TError, TData>(
      ['getAnnouncementList', variables],
      useMyFetcher<GetAnnouncementListQuery, GetAnnouncementListQueryVariables>(GetAnnouncementListDocument).bind(null, variables),
      options
    );
export const useInfiniteGetAnnouncementListQuery = <
      TData = GetAnnouncementListQuery,
      TError = unknown
    >(
      variables: GetAnnouncementListQueryVariables,
      options?: UseInfiniteQueryOptions<GetAnnouncementListQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetAnnouncementListQuery, GetAnnouncementListQueryVariables>(GetAnnouncementListDocument)
    return useInfiniteQuery<GetAnnouncementListQuery, TError, TData>(
      ['getAnnouncementList.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetChapterDocument = `
    query getChapter($chapterId: Int!) {
  chapter(id: $chapterId) {
    id
    titleId
    collectionId
    sequenceNum
    displayName
    originalName
    content
    free
    points
    rental
    listingStartDate
    listingEndDate
    listingStatus
    createdTime
    updatedTime
  }
}
    `;
export const useGetChapterQuery = <
      TData = GetChapterQuery,
      TError = unknown
    >(
      variables: GetChapterQueryVariables,
      options?: UseQueryOptions<GetChapterQuery, TError, TData>
    ) =>
    useQuery<GetChapterQuery, TError, TData>(
      ['getChapter', variables],
      useMyFetcher<GetChapterQuery, GetChapterQueryVariables>(GetChapterDocument).bind(null, variables),
      options
    );
export const useInfiniteGetChapterQuery = <
      TData = GetChapterQuery,
      TError = unknown
    >(
      variables: GetChapterQueryVariables,
      options?: UseInfiniteQueryOptions<GetChapterQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetChapterQuery, GetChapterQueryVariables>(GetChapterDocument)
    return useInfiniteQuery<GetChapterQuery, TError, TData>(
      ['getChapter.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetChapterListDocument = `
    query getChapterList($titleId: Int!, $listingStatus: ListingStatus) {
  title(id: $titleId) {
    id
    collections {
      id
      titleId
      displayName
      sequenceNum
      countOfChapters
      chapters(listingStatus: $listingStatus) {
        id
        titleId
        collectionId
        displayName
        sequenceNum
        free
        wordCount
        listingStartDate
        listingEndDate
        listingStatus
      }
    }
  }
}
    `;
export const useGetChapterListQuery = <
      TData = GetChapterListQuery,
      TError = unknown
    >(
      variables: GetChapterListQueryVariables,
      options?: UseQueryOptions<GetChapterListQuery, TError, TData>
    ) =>
    useQuery<GetChapterListQuery, TError, TData>(
      ['getChapterList', variables],
      useMyFetcher<GetChapterListQuery, GetChapterListQueryVariables>(GetChapterListDocument).bind(null, variables),
      options
    );
export const useInfiniteGetChapterListQuery = <
      TData = GetChapterListQuery,
      TError = unknown
    >(
      variables: GetChapterListQueryVariables,
      options?: UseInfiniteQueryOptions<GetChapterListQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetChapterListQuery, GetChapterListQueryVariables>(GetChapterListDocument)
    return useInfiniteQuery<GetChapterListQuery, TError, TData>(
      ['getChapterList.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetChapterListingStatusDocument = `
    query getChapterListingStatus($chapterId: Int!) {
  chapter(id: $chapterId) {
    listingStatus
  }
}
    `;
export const useGetChapterListingStatusQuery = <
      TData = GetChapterListingStatusQuery,
      TError = unknown
    >(
      variables: GetChapterListingStatusQueryVariables,
      options?: UseQueryOptions<GetChapterListingStatusQuery, TError, TData>
    ) =>
    useQuery<GetChapterListingStatusQuery, TError, TData>(
      ['getChapterListingStatus', variables],
      useMyFetcher<GetChapterListingStatusQuery, GetChapterListingStatusQueryVariables>(GetChapterListingStatusDocument).bind(null, variables),
      options
    );
export const useInfiniteGetChapterListingStatusQuery = <
      TData = GetChapterListingStatusQuery,
      TError = unknown
    >(
      variables: GetChapterListingStatusQueryVariables,
      options?: UseInfiniteQueryOptions<GetChapterListingStatusQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetChapterListingStatusQuery, GetChapterListingStatusQueryVariables>(GetChapterListingStatusDocument)
    return useInfiniteQuery<GetChapterListingStatusQuery, TError, TData>(
      ['getChapterListingStatus.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetChapterSettingDocument = `
    query getChapterSetting($chapterId: Int!) {
  chapter(id: $chapterId) {
    id
    titleId
    displayName
    originalName
    free
    points
    rental
    listingStatus
  }
}
    `;
export const useGetChapterSettingQuery = <
      TData = GetChapterSettingQuery,
      TError = unknown
    >(
      variables: GetChapterSettingQueryVariables,
      options?: UseQueryOptions<GetChapterSettingQuery, TError, TData>
    ) =>
    useQuery<GetChapterSettingQuery, TError, TData>(
      ['getChapterSetting', variables],
      useMyFetcher<GetChapterSettingQuery, GetChapterSettingQueryVariables>(GetChapterSettingDocument).bind(null, variables),
      options
    );
export const useInfiniteGetChapterSettingQuery = <
      TData = GetChapterSettingQuery,
      TError = unknown
    >(
      variables: GetChapterSettingQueryVariables,
      options?: UseInfiniteQueryOptions<GetChapterSettingQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetChapterSettingQuery, GetChapterSettingQueryVariables>(GetChapterSettingDocument)
    return useInfiniteQuery<GetChapterSettingQuery, TError, TData>(
      ['getChapterSetting.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetChapterSortingListDocument = `
    query getChapterSortingList($titleId: Int!) {
  title(id: $titleId) {
    id
    collections {
      id
      displayName
      chapters {
        id
        displayName
        sequenceNum
        listingStatus
      }
    }
  }
}
    `;
export const useGetChapterSortingListQuery = <
      TData = GetChapterSortingListQuery,
      TError = unknown
    >(
      variables: GetChapterSortingListQueryVariables,
      options?: UseQueryOptions<GetChapterSortingListQuery, TError, TData>
    ) =>
    useQuery<GetChapterSortingListQuery, TError, TData>(
      ['getChapterSortingList', variables],
      useMyFetcher<GetChapterSortingListQuery, GetChapterSortingListQueryVariables>(GetChapterSortingListDocument).bind(null, variables),
      options
    );
export const useInfiniteGetChapterSortingListQuery = <
      TData = GetChapterSortingListQuery,
      TError = unknown
    >(
      variables: GetChapterSortingListQueryVariables,
      options?: UseInfiniteQueryOptions<GetChapterSortingListQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetChapterSortingListQuery, GetChapterSortingListQueryVariables>(GetChapterSortingListDocument)
    return useInfiniteQuery<GetChapterSortingListQuery, TError, TData>(
      ['getChapterSortingList.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetChapterViewCountEntriesDocument = `
    query getChapterViewCountEntries($titleId: Float!) {
  chapterViewCountEntries(titleId: $titleId)
}
    `;
export const useGetChapterViewCountEntriesQuery = <
      TData = GetChapterViewCountEntriesQuery,
      TError = unknown
    >(
      variables: GetChapterViewCountEntriesQueryVariables,
      options?: UseQueryOptions<GetChapterViewCountEntriesQuery, TError, TData>
    ) =>
    useQuery<GetChapterViewCountEntriesQuery, TError, TData>(
      ['getChapterViewCountEntries', variables],
      useMyFetcher<GetChapterViewCountEntriesQuery, GetChapterViewCountEntriesQueryVariables>(GetChapterViewCountEntriesDocument).bind(null, variables),
      options
    );
export const useInfiniteGetChapterViewCountEntriesQuery = <
      TData = GetChapterViewCountEntriesQuery,
      TError = unknown
    >(
      variables: GetChapterViewCountEntriesQueryVariables,
      options?: UseInfiniteQueryOptions<GetChapterViewCountEntriesQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetChapterViewCountEntriesQuery, GetChapterViewCountEntriesQueryVariables>(GetChapterViewCountEntriesDocument)
    return useInfiniteQuery<GetChapterViewCountEntriesQuery, TError, TData>(
      ['getChapterViewCountEntries.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetCommentsDocument = `
    query GetComments($options: GetCommentsOptions!) {
  comments(options: $options) {
    edges {
      node {
        id
        content
        titleId
        collectionId
        chapterId
        paragraph
        parentCommentId
        mentionedMemberId
        mentionedMemberDisplayName
        commenterId
        commenterAvatar
        commenterKind
        commenterDisplayName
        childrenCount
        isTitleOwner
        createdTime
        updatedTime
        deletedTime
        isPinned
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    parentComment {
      id
      content
      titleId
      collectionId
      chapterId
      paragraph
      parentCommentId
      mentionedMemberId
      mentionedMemberDisplayName
      commenterId
      commenterAvatar
      commenterKind
      commenterDisplayName
      childrenCount
      isTitleOwner
      createdTime
      updatedTime
      deletedTime
      isPinned
    }
  }
}
    `;
export const useGetCommentsQuery = <
      TData = GetCommentsQuery,
      TError = unknown
    >(
      variables: GetCommentsQueryVariables,
      options?: UseQueryOptions<GetCommentsQuery, TError, TData>
    ) =>
    useQuery<GetCommentsQuery, TError, TData>(
      ['GetComments', variables],
      useMyFetcher<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument).bind(null, variables),
      options
    );
export const useInfiniteGetCommentsQuery = <
      TData = GetCommentsQuery,
      TError = unknown
    >(
      variables: GetCommentsQueryVariables,
      options?: UseInfiniteQueryOptions<GetCommentsQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument)
    return useInfiniteQuery<GetCommentsQuery, TError, TData>(
      ['GetComments.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetContestDocument = `
    query getContest($contestId: Int!) {
  contest(id: $contestId) {
    id
    displayName
    content
    runningFrom
    runningTo
  }
}
    `;
export const useGetContestQuery = <
      TData = GetContestQuery,
      TError = unknown
    >(
      variables: GetContestQueryVariables,
      options?: UseQueryOptions<GetContestQuery, TError, TData>
    ) =>
    useQuery<GetContestQuery, TError, TData>(
      ['getContest', variables],
      useMyFetcher<GetContestQuery, GetContestQueryVariables>(GetContestDocument).bind(null, variables),
      options
    );
export const useInfiniteGetContestQuery = <
      TData = GetContestQuery,
      TError = unknown
    >(
      variables: GetContestQueryVariables,
      options?: UseInfiniteQueryOptions<GetContestQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetContestQuery, GetContestQueryVariables>(GetContestDocument)
    return useInfiniteQuery<GetContestQuery, TError, TData>(
      ['getContest.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetContestGroupListDocument = `
    query getContestGroupList($contestId: Int!) {
  contestGroupList(contestId: $contestId) {
    id
    displayName
  }
  myContestGroupList(contestId: $contestId) {
    id
    displayName
  }
}
    `;
export const useGetContestGroupListQuery = <
      TData = GetContestGroupListQuery,
      TError = unknown
    >(
      variables: GetContestGroupListQueryVariables,
      options?: UseQueryOptions<GetContestGroupListQuery, TError, TData>
    ) =>
    useQuery<GetContestGroupListQuery, TError, TData>(
      ['getContestGroupList', variables],
      useMyFetcher<GetContestGroupListQuery, GetContestGroupListQueryVariables>(GetContestGroupListDocument).bind(null, variables),
      options
    );
export const useInfiniteGetContestGroupListQuery = <
      TData = GetContestGroupListQuery,
      TError = unknown
    >(
      variables: GetContestGroupListQueryVariables,
      options?: UseInfiniteQueryOptions<GetContestGroupListQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetContestGroupListQuery, GetContestGroupListQueryVariables>(GetContestGroupListDocument)
    return useInfiniteQuery<GetContestGroupListQuery, TError, TData>(
      ['getContestGroupList.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetContestableTitleListDocument = `
    query getContestableTitleList($groupId: Int!) {
  contestableTitleList(groupId: $groupId) {
    titleId
    displayName
    cover
    attended
  }
}
    `;
export const useGetContestableTitleListQuery = <
      TData = GetContestableTitleListQuery,
      TError = unknown
    >(
      variables: GetContestableTitleListQueryVariables,
      options?: UseQueryOptions<GetContestableTitleListQuery, TError, TData>
    ) =>
    useQuery<GetContestableTitleListQuery, TError, TData>(
      ['getContestableTitleList', variables],
      useMyFetcher<GetContestableTitleListQuery, GetContestableTitleListQueryVariables>(GetContestableTitleListDocument).bind(null, variables),
      options
    );
export const useInfiniteGetContestableTitleListQuery = <
      TData = GetContestableTitleListQuery,
      TError = unknown
    >(
      variables: GetContestableTitleListQueryVariables,
      options?: UseInfiniteQueryOptions<GetContestableTitleListQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetContestableTitleListQuery, GetContestableTitleListQueryVariables>(GetContestableTitleListDocument)
    return useInfiniteQuery<GetContestableTitleListQuery, TError, TData>(
      ['getContestableTitleList.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetMyChapterCommentsDocument = `
    query getMyChapterComments($input: GetChapterCommentsInput!) {
  myChapterComments(input: $input) {
    edges {
      node {
        id
        content
        titleId
        collectionId
        chapterId
        paragraph
        parentCommentId
        mentionedMemberId
        mentionedMemberDisplayName
        commenterId
        commenterAvatar
        commenterKind
        commenterDisplayName
        childrenCount
        isTitleOwner
        createdTime
        updatedTime
        deletedTime
        authorIsChecked
        chapter {
          titleId
          chapterDisplayName
          collectionDisplayName
          titleDisplayName
        }
        isPinned
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export const useGetMyChapterCommentsQuery = <
      TData = GetMyChapterCommentsQuery,
      TError = unknown
    >(
      variables: GetMyChapterCommentsQueryVariables,
      options?: UseQueryOptions<GetMyChapterCommentsQuery, TError, TData>
    ) =>
    useQuery<GetMyChapterCommentsQuery, TError, TData>(
      ['getMyChapterComments', variables],
      useMyFetcher<GetMyChapterCommentsQuery, GetMyChapterCommentsQueryVariables>(GetMyChapterCommentsDocument).bind(null, variables),
      options
    );
export const useInfiniteGetMyChapterCommentsQuery = <
      TData = GetMyChapterCommentsQuery,
      TError = unknown
    >(
      variables: GetMyChapterCommentsQueryVariables,
      options?: UseInfiniteQueryOptions<GetMyChapterCommentsQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetMyChapterCommentsQuery, GetMyChapterCommentsQueryVariables>(GetMyChapterCommentsDocument)
    return useInfiniteQuery<GetMyChapterCommentsQuery, TError, TData>(
      ['getMyChapterComments.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetPinnedCommentDocument = `
    query getPinnedComment($titleId: Int!) {
  getPinnedComment(titleId: $titleId) {
    id
    content
    isTitleOwner
    commenterDisplayName
    commenterAvatar
    commenterKind
    commenterId
    createdAt
    updatedAt
  }
}
    `;
export const useGetPinnedCommentQuery = <
      TData = GetPinnedCommentQuery,
      TError = unknown
    >(
      variables: GetPinnedCommentQueryVariables,
      options?: UseQueryOptions<GetPinnedCommentQuery, TError, TData>
    ) =>
    useQuery<GetPinnedCommentQuery, TError, TData>(
      ['getPinnedComment', variables],
      useMyFetcher<GetPinnedCommentQuery, GetPinnedCommentQueryVariables>(GetPinnedCommentDocument).bind(null, variables),
      options
    );
export const useInfiniteGetPinnedCommentQuery = <
      TData = GetPinnedCommentQuery,
      TError = unknown
    >(
      variables: GetPinnedCommentQueryVariables,
      options?: UseInfiniteQueryOptions<GetPinnedCommentQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetPinnedCommentQuery, GetPinnedCommentQueryVariables>(GetPinnedCommentDocument)
    return useInfiniteQuery<GetPinnedCommentQuery, TError, TData>(
      ['getPinnedComment.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetRevenueDetailDocument = `
    query getRevenueDetail($revenueDetailOptions: RevenueDetailOptions!) {
  revenueDetail(revenueDetailOptions: $revenueDetailOptions) {
    total
    revenueDetailItems {
      titleDisplayName
      chapterDisplayName
      operation
      occurrences
      revenue
    }
  }
}
    `;
export const useGetRevenueDetailQuery = <
      TData = GetRevenueDetailQuery,
      TError = unknown
    >(
      variables: GetRevenueDetailQueryVariables,
      options?: UseQueryOptions<GetRevenueDetailQuery, TError, TData>
    ) =>
    useQuery<GetRevenueDetailQuery, TError, TData>(
      ['getRevenueDetail', variables],
      useMyFetcher<GetRevenueDetailQuery, GetRevenueDetailQueryVariables>(GetRevenueDetailDocument).bind(null, variables),
      options
    );
export const useInfiniteGetRevenueDetailQuery = <
      TData = GetRevenueDetailQuery,
      TError = unknown
    >(
      variables: GetRevenueDetailQueryVariables,
      options?: UseInfiniteQueryOptions<GetRevenueDetailQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetRevenueDetailQuery, GetRevenueDetailQueryVariables>(GetRevenueDetailDocument)
    return useInfiniteQuery<GetRevenueDetailQuery, TError, TData>(
      ['getRevenueDetail.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const GetViolationGuidelineDocument = `
    query getViolationGuideline {
  violatedGuidelineList {
    id
    displayName
  }
}
    `;
export const useGetViolationGuidelineQuery = <
      TData = GetViolationGuidelineQuery,
      TError = unknown
    >(
      variables?: GetViolationGuidelineQueryVariables,
      options?: UseQueryOptions<GetViolationGuidelineQuery, TError, TData>
    ) =>
    useQuery<GetViolationGuidelineQuery, TError, TData>(
      variables === undefined ? ['getViolationGuideline'] : ['getViolationGuideline', variables],
      useMyFetcher<GetViolationGuidelineQuery, GetViolationGuidelineQueryVariables>(GetViolationGuidelineDocument).bind(null, variables),
      options
    );
export const useInfiniteGetViolationGuidelineQuery = <
      TData = GetViolationGuidelineQuery,
      TError = unknown
    >(
      variables?: GetViolationGuidelineQueryVariables,
      options?: UseInfiniteQueryOptions<GetViolationGuidelineQuery, TError, TData>
    ) =>{
    const query = useMyFetcher<GetViolationGuidelineQuery, GetViolationGuidelineQueryVariables>(GetViolationGuidelineDocument)
    return useInfiniteQuery<GetViolationGuidelineQuery, TError, TData>(
      variables === undefined ? ['getViolationGuideline.infinite'] : ['getViolationGuideline.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const UpdateChapterDocument = `
    mutation updateChapter($updateChapterData: UpdateChapterInput!) {
  updateChapter(updateChapterData: $updateChapterData) {
    affectedRows
  }
}
    `;
export const useUpdateChapterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateChapterMutation, TError, UpdateChapterMutationVariables, TContext>) =>
    useMutation<UpdateChapterMutation, TError, UpdateChapterMutationVariables, TContext>(
      ['updateChapter'],
      useMyFetcher<UpdateChapterMutation, UpdateChapterMutationVariables>(UpdateChapterDocument),
      options
    );
export const UpdateChapterOrderDocument = `
    mutation updateChapterOrder($updateSequenceNumsData: UpdateSequenceNumsInput!) {
  updateSequenceNums(updateSequenceNumsData: $updateSequenceNumsData) {
    updatedChapters
  }
}
    `;
export const useUpdateChapterOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateChapterOrderMutation, TError, UpdateChapterOrderMutationVariables, TContext>) =>
    useMutation<UpdateChapterOrderMutation, TError, UpdateChapterOrderMutationVariables, TContext>(
      ['updateChapterOrder'],
      useMyFetcher<UpdateChapterOrderMutation, UpdateChapterOrderMutationVariables>(UpdateChapterOrderDocument),
      options
    );
export const UpdateCollectionDocument = `
    mutation updateCollection($updateCollectionData: UpdateCollectionInput!) {
  updateCollection(updateCollectionData: $updateCollectionData) {
    affectedRows
  }
}
    `;
export const useUpdateCollectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCollectionMutation, TError, UpdateCollectionMutationVariables, TContext>) =>
    useMutation<UpdateCollectionMutation, TError, UpdateCollectionMutationVariables, TContext>(
      ['updateCollection'],
      useMyFetcher<UpdateCollectionMutation, UpdateCollectionMutationVariables>(UpdateCollectionDocument),
      options
    );
export const UpdateCommentReadStatusDocument = `
    mutation updateCommentReadStatus($commentId: Int!) {
  authorCheckComment(commentId: $commentId)
}
    `;
export const useUpdateCommentReadStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCommentReadStatusMutation, TError, UpdateCommentReadStatusMutationVariables, TContext>) =>
    useMutation<UpdateCommentReadStatusMutation, TError, UpdateCommentReadStatusMutationVariables, TContext>(
      ['updateCommentReadStatus'],
      useMyFetcher<UpdateCommentReadStatusMutation, UpdateCommentReadStatusMutationVariables>(UpdateCommentReadStatusDocument),
      options
    );
export const UpdatePinCommentDocument = `
    mutation updatePinComment($pin: Boolean!, $commentId: Int!) {
  pinComment(pin: $pin, id: $commentId) {
    isPinned
  }
}
    `;
export const useUpdatePinCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePinCommentMutation, TError, UpdatePinCommentMutationVariables, TContext>) =>
    useMutation<UpdatePinCommentMutation, TError, UpdatePinCommentMutationVariables, TContext>(
      ['updatePinComment'],
      useMyFetcher<UpdatePinCommentMutation, UpdatePinCommentMutationVariables>(UpdatePinCommentDocument),
      options
    );
export const UploadImageDocument = `
    mutation uploadImage($prefix: CloudImagePrefix!, $image: Upload!) {
  uploadImage(prefix: $prefix, image: $image) {
    path
    url
  }
}
    `;
export const useUploadImageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UploadImageMutation, TError, UploadImageMutationVariables, TContext>) =>
    useMutation<UploadImageMutation, TError, UploadImageMutationVariables, TContext>(
      ['uploadImage'],
      useMyFetcher<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument),
      options
    );