import { IS_CLIENT_SIDE, IS_PROD_EXEC_ENV } from '@/utils'
import { GraphQLClient } from 'graphql-request'
import { useEffect, useRef } from 'react'

// 客製化Fetcher的型別
type MyFetcher = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
) => (variables?: TVariables) => Promise<TData>

export const useMyFetcher: MyFetcher = (query, options = {}) => {
  const endpoint = useRef(process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT as string)

  useEffect(() => {
    // 因應大陸市場需求，動態產生api base url
    if (
      IS_CLIENT_SIDE &&
      IS_PROD_EXEC_ENV &&
      window.location.origin !== process.env.NEXT_PUBLIC_BASE_URL
    ) {
      const { origin } = window.location

      endpoint.current = origin.replace('creator', 'api') + '/graphql'
    }
  }, [])

  return async variables => {
    try {
      const graphQLClient = new GraphQLClient(endpoint.current, { credentials: 'include' })

      const res = await graphQLClient.request({
        document: query,
        variables,
        requestHeaders: { ...options },
      })

      return res
    } catch (error: any) {
      throw error
    }
  }
}
